import React, { useState } from 'react';
// Services
import { sendRequest } from '../../services/sendRequest';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
//Images
import { IoMdClose } from 'react-icons/io';
import availableCardImage from '../../Images/Coupon/availableCoupon.png';
import rejectedCardImage from '../../Images/Coupon/rejectedCoupon.png';
import acceptedCardImage from '../../Images/Coupon/aceptedCoupon.png';
// Styles
import './CardCoupon.css';

const CardCoupon = ({
  benefit_id,
  benefit,
  requested,
  type,
  couponUpdateHandler,
  estado,
  description,
}) => {
  const [requestProcess, setRequestProcess] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState('');
  const [dateRequest, setDateRequest] = useState('');
  const [detailOption, setDetailOption] = useState('');

  const initialMessage = {
    status: '',
    message: '',
  };

  const [responseMessage, setResponseMessage] = useState(initialMessage);

  const available = estado === 0 || estado === 3;

  // Función de activación de proceso de solicitud de beneficio
  const requestProcessHandler = () => {
    if (available) {
      setRequestProcess(true);
      setDateRequest('');
    }
  };

  // Funcion de manejo de errores en fechas de solicitud por el usuario
  const formatDateHandler = () => {
    if (type === 'select' || type === 'specific_day') {
      return formatDate(dateRequest);
    } else if (type === 'range') {
      const from = formatDate(dateRequest[0]);
      const to = formatDate(dateRequest[1]);
      const dateForRequest = `${from} a ${to}`;
      return dateForRequest;
    } else if (type === 'none') {
      return new Date();
    } else {
      return type;
    }
  };

  const validate = () => {
    if (benefit_id === 10 || benefit_id === 11) {
      if (detailOption === '') {
        setResponseMessage({
          status: 'error',
          message: 'Por favor selecciona una jornada valida',
        });
        setTimeout(() => {
          setRequestSuccessful('');
          setResponseMessage(initialMessage);
        }, 2500);
        return;
      }
    } else if (benefit_id === 9) {
      return true;
    }

    if (!dateRequest) {
      if (type === 'select' || type === 'specific_day') {
        setRequestSuccessful('errorDate');
        setResponseMessage({
          status: 'error',
          message: 'Debes seleccionar una fecha',
        });
      } else if (type === 'range') {
        setRequestSuccessful('errorRangeDate');
        setResponseMessage({
          status: 'error',
          message: 'Debe seleccionar un rango de fechas',
        });
      }
      setTimeout(() => {
        setRequestSuccessful('');
        setResponseMessage(initialMessage);
      }, 2500);
      return false; // Indicar que la validación falló
    }
    return true; // Indicar que la validación fue exitosa
  };

  // Manejador de solicitudes de beneficios
  const reqBenefitHandler = async () => {
    if (!validate()) {
      return; // Detener la ejecución si la validación falló
    }
    const date = formatDateHandler();
    try {
      //   Realizar la solicitud al servidor
      const res = await sendRequest(
        benefit_id,
        localStorage.id,
        date,
        detailOption
      );

      // Manejar la respuesta según el estado
      if (res.status === 200) {
        setRequestSuccessful('successful');
        setResponseMessage({
          status: 'successful',
          message: 'Solicitud procesada correctamente',
        });
        couponUpdateHandler(
          `updated benefit ${benefit_id} requested ${requested}`
        );
      } else if (res.status === 400) {
        setRequestSuccessful(res.data.error);
        setResponseMessage({
          status: 'error',
          message: res.data.error,
        });
      } else {
        setResponseMessage({
          status: 'error',
          message: 'Error al realizar la solicitud',
        });
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setResponseMessage({
        status: 'error',
        message: 'Error al realizar la solicitud',
      });
    } finally {
      // Finalizar el proceso de solicitud después de un tiempo
      setTimeout(() => {
        setRequestProcess(false);
        setRequestSuccessful('');
        setResponseMessage(initialMessage);
      }, 2500);
    }
  };

  // Función para formatear la fecha
  const formatDate = (dateString) => {
    if (!dateString) {
      setResponseMessage({
        status: 'error',
        message: 'Debes seleccionar una fecha',
      });
      return null;
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      setResponseMessage({
        status: 'error',
        message: 'Debes seleccionar una fecha',
      });
      return null;
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`;
  };

  // Función para asignar el estado de cada beneficio
  const getStateRequestImage = () => {
    switch (estado) {
      case 0:
        return availableCardImage;
      case 1:
        return availableCardImage;
      case 2:
        return acceptedCardImage;
      case 3:
        return rejectedCardImage;
      default:
        return null;
    }
  };

  const handleSelectedOption = (e) => {
    setDetailOption(e.target.value);
  };

  return (
    <>
      <div className={available ? 'CardCoupon CardCouponAvailable' : 'CardCoupon'} onClick={requestProcessHandler} role='button'>
        <img src={getStateRequestImage()} alt='' loading='lazy' />
        <div className='CardCouponInfo'>
          <p className='CardCouponInfoNameBenefit'>{benefit}</p>
          <p className='CardCouponInfoDescription'>{description}</p>
        </div>
      </div>

      {requestProcess && (
        <div className='modal-overlay'>
          <div className='modal'>
            <div>
              <p>¿Quieres utilizar este cupón?</p>
              <p className='benefitName'>
                <i>{benefit}</i>
              </p>
              {benefit_id === 7 && <p>Debes seleccionar un rango de fechas</p>}
              {type !== 'none' && (
                <Calendar
                  className='calendar'
                  value={dateRequest}
                  onChange={setDateRequest}
                  minDate={new Date()}
                  selectRange={type === 'range' ? true : false}
                />
              )}
              <div className='handlerOptions'>
                {(benefit_id === 10 || benefit_id === 11) && (
                  <>
                    <label htmlFor='detail'>
                      Selecciona la jornada para el beneficio:
                    </label>
                    <select
                      id='detail'
                      value={detailOption}
                      onChange={handleSelectedOption}
                    >
                      <option value='' disabled>
                        Selecciona una jornada...
                      </option>
                      <option value='mañana'>Mañana</option>
                      <option value='tarde'>Tarde</option>
                    </select>
                  </>
                )}
                {requestSuccessful === '' && (
                  <button className='requestButton' onClick={reqBenefitHandler}>
                    Aceptar
                  </button>
                )}
                {responseMessage.status && (
                  <p className={responseMessage.status}>
                    {responseMessage.message}
                  </p>
                )}
              </div>
            </div>
            <button onClick={() => setRequestProcess(false)}>
              <IoMdClose />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CardCoupon;
