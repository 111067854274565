import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineLogout } from 'react-icons/hi';
// Styles
import './Logout.css';

const Login = () => {
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('request');
    localStorage.removeItem('birthday');
    localStorage.removeItem('token');
    navigate(`/`);
  };

  return (
    <button className='logoutButton' onClick={logoutHandler}>
      <span>Logout</span>
      <HiOutlineLogout className='logoutIcon' />
    </button>
  );
};

export default Login;
