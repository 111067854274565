import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para obtener datos de beneficios
export const sendRequest = async (
  benefit_id,
  employee_id,
  dateForRequest,
  detailOption
) => {
  try {
    let url_benefits = '/api/request';
    const res = await fetchWithoutToken.post(
      url_benefits,
      {
        beneficio_id: benefit_id,
        empleado_id: employee_id,
        fecha_solicitud: dateForRequest,
        estado_id: 1,
        detalle: detailOption,
      },
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    );
    return res;
  } catch (error) {
    return error.response;
  }
};
