import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Services
import { getRequest } from '../../services/getRequest';
// Components
import Loader from '../../Components/Loader';
import Container from '../../Components/Container';
import CardRequest from '../../Components/CardRequest';
// Styles
import './RequestPage.css';

const RequestPage = () => {
  const navigate = useNavigate();
  const [couponStatus, setCouponStatus] = useState('initial');

  const [requestPending, setRequestPending] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.name || !localStorage.email) {
      localStorage.setItem('request', 'request');
      navigate('/');
    } else {
      setIsLoading(true);
      getRequest(localStorage.id)
        .then((res) => {
          if (res.status === 200) {
            const filteredItems = res.data.filter(
              (item) => item.estado_id === 1
            );
            setRequestPending(filteredItems);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching benefits:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponStatus, localStorage.id]);

  const couponUpdateHandler = (status) => {
    setCouponStatus(status);
  };

  return (
    <main className='request'>
      <Container>
        <h2 className='requestTitle'>Administrador de solicitudes</h2>
        {isLoading ? (
          <section className='loadingContainer'>
            <Loader />
          </section>
        ) : requestPending.length === 0 ? (
          <div className='noRequestContainer'>
            <h3 className='noRequest'>No hay solicitudes pendientes</h3>
          </div>
        ) : (
          <>
            <div className='requestContainerTitle'>
              <h3>Solicitudes pendientes ({requestPending.length})</h3>
            </div>
            <div className='requestContainer'>
              {requestPending?.map((element, i) => {
                return (
                  <CardRequest
                    key={i}
                    element={element}
                    couponUpdateHandler={couponUpdateHandler}
                  />
                );
              })}
            </div>
          </>
        )}
      </Container>
    </main>
  );
};

export default RequestPage;
