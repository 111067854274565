// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-component__olib {
	max-width: 1300px;
	padding: 0 0.8rem;
	margin: 0 auto;
}

@media (min-width: 780px) {
	.container-component__olib {
		padding: 0 1.3rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/Container/Container.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,iBAAiB;CACjB,cAAc;AACf;;AAEA;CACC;EACC,iBAAiB;CAClB;AACD","sourcesContent":[".container-component__olib {\r\n\tmax-width: 1300px;\r\n\tpadding: 0 0.8rem;\r\n\tmargin: 0 auto;\r\n}\r\n\r\n@media (min-width: 780px) {\r\n\t.container-component__olib {\r\n\t\tpadding: 0 1.3rem;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
