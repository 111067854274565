// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1,
h2,
h3,
h4,
p,
li,
ul,
a,
button {
	margin: 0;
	padding: 0;
	color: #1b2831;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
}

button {
	border: none;
}

@keyframes sweep {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

div,
header,
section,
ul,
details[open] summary ~ * {
	animation: sweep 0.5s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;CACV,qCAAqC;AACtC;;AAEA;CACC;WACU;AACX;;AAEA;;;;;;;;;CASC,SAAS;CACT,UAAU;CACV,cAAc;AACf;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD;;AAEA;;;;;CAKC,iCAAiC;AAClC","sourcesContent":["body {\n\tmargin: 0;\n\tpadding: 0;\n\tfont-family: 'Montserrat', sans-serif;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n\t\tmonospace;\n}\n\nh1,\nh2,\nh3,\nh4,\np,\nli,\nul,\na,\nbutton {\n\tmargin: 0;\n\tpadding: 0;\n\tcolor: #1b2831;\n}\n\nli {\n\tlist-style: none;\n}\n\na {\n\ttext-decoration: none;\n}\n\nbutton {\n\tborder: none;\n}\n\n@keyframes sweep {\n\t0% {\n\t\topacity: 0;\n\t}\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\ndiv,\nheader,\nsection,\nul,\ndetails[open] summary ~ * {\n\tanimation: sweep 0.5s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
