import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para obtener datos de beneficios
export const handleRequest = async (
  solicitado_id,
  estado_id,
  reason,
  usuario_id,
  fecha_solicitud,
  beneficio_id
) => {
  try {
    let url_benefits = '/api/request/handle-request';
    const res = await fetchWithoutToken.post(
      url_benefits,
      {
        solicitado_id,
        estado_id,
        razon: reason,
        usuario_id,
        fecha_solicitud,
        beneficio_id,
      },
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    return [];
  }
};
