// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request {
  min-height: 80vh;
}

.requestTitle {
  text-align: center;
  margin: 40px 0;
}

@media (min-width: 1100px) {
  .requestTitle {
    text-align: center;
    margin: 80px 0 40px;
  }
}

.requestContainer {
  background-color: #1b28310d;
  padding: 40px 30px;
  max-width: 940px;
  margin: 0 auto;
}

@media (min-width: 900px) {
  .requestContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

.noRequestContainer {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noRequest {
  font-size: 16px;
}

.requestContainerTitle {
  background-color: #1b2831;
  text-align: center;
  height: 60px;
  border-radius: 15px 15px 0 0;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestContainerTitle h3 {
  color: white;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Views/RequestPage/RequestPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".request {\r\n  min-height: 80vh;\r\n}\r\n\r\n.requestTitle {\r\n  text-align: center;\r\n  margin: 40px 0;\r\n}\r\n\r\n@media (min-width: 1100px) {\r\n  .requestTitle {\r\n    text-align: center;\r\n    margin: 80px 0 40px;\r\n  }\r\n}\r\n\r\n.requestContainer {\r\n  background-color: #1b28310d;\r\n  padding: 40px 30px;\r\n  max-width: 940px;\r\n  margin: 0 auto;\r\n}\r\n\r\n@media (min-width: 900px) {\r\n  .requestContainer {\r\n    grid-template-columns: repeat(2, 1fr);\r\n  }\r\n}\r\n\r\n.noRequestContainer {\r\n  height: 50vh;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.noRequest {\r\n  font-size: 16px;\r\n}\r\n\r\n.requestContainerTitle {\r\n  background-color: #1b2831;\r\n  text-align: center;\r\n  height: 60px;\r\n  border-radius: 15px 15px 0 0;\r\n  max-width: 1000px;\r\n  margin: 0 auto;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.requestContainerTitle h3 {\r\n  color: white;\r\n  font-size: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
