// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  min-height: 150px;
  background-color: #1b2831;
  color: white;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 850px) {
  header {
    padding: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}

@media (min-width: 850px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.header h1 {
  font-weight: 600;
  color: white;
  text-align: center;
}

.headerNav {
  display: grid;
  grid-template-columns: 6fr 1fr;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}

@media (min-width: 850px) {
  .headerNav {
    display: flex;
    width: 360px;
    justify-content: space-between;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;AACF;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,8BAA8B;EAC9B,SAAS;EACT,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,YAAY;IACZ,8BAA8B;EAChC;AACF","sourcesContent":["header {\r\n  min-height: 150px;\r\n  background-color: #1b2831;\r\n  color: white;\r\n  padding: 20px 0;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n@media (min-width: 850px) {\r\n  header {\r\n    padding: 0;\r\n  }\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 30px;\r\n  width: 100%;\r\n}\r\n\r\n@media (min-width: 850px) {\r\n  .header {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n  }\r\n}\r\n\r\n.header h1 {\r\n  font-weight: 600;\r\n  color: white;\r\n  text-align: center;\r\n}\r\n\r\n.headerNav {\r\n  display: grid;\r\n  grid-template-columns: 6fr 1fr;\r\n  justify-content: space-between;\r\n  gap: 30px;\r\n  width: 100%;\r\n}\r\n\r\n@media (min-width: 850px) {\r\n  .headerNav {\r\n    display: flex;\r\n    width: 360px;\r\n    justify-content: space-between;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
