import React from 'react';
import appPages from './pages';
import { Route, Routes } from 'react-router-dom';

const Router = () => {
	const pageRoutes = appPages.map(({ path, title, element }) => {
		return <Route key={title} path={`/${path}`} element={element} />;
	});

	return <Routes>{pageRoutes}</Routes>;
};

export default Router;
