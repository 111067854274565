// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*======================
    404 page
=======================*/

.page_404 {
	width: 100%;
	padding: 40px 0;
	background: #fff;
	margin-top: 100px;
	min-height: 80vh;
}
.page_404 .row {
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-right: 0;
	padding-right: 0;
}

.page_404 .col-sm-12 {
	margin-bottom: 0;
	padding-right: 0;
}

.page_404 .container {
	margin-bottom: 0;
	text-align: center;
	
}

.page_404 img {
	width: 100%;
}

.four_zero_four_bg {
	background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
	height: 400px;
	background-position: center;
	background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
	font-size: 80px;
}

.four_zero_four_bg h3 {
	font-size: 80px;
}

.link_404 {
	color: #fff !important;
	padding: 10px 20px;
	background: #00bc92;
	margin: 20px 0;
	display: inline-block;
	border-radius: 8px;
}
.contant_box_404 {
	margin-top: -50px;
}
`, "",{"version":3,"sources":["webpack://./src/Views/NotFound/not-found.css"],"names":[],"mappings":"AAAA;;wBAEwB;;AAExB;CACC,WAAW;CACX,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,gBAAgB;AACjB;AACA;CACC,iBAAiB;CACjB,oBAAoB;CACpB,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;CAChB,kBAAkB;;AAEnB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,+FAA+F;CAC/F,aAAa;CACb,2BAA2B;CAC3B,4BAA4B;AAC7B;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,sBAAsB;CACtB,kBAAkB;CAClB,mBAAmB;CACnB,cAAc;CACd,qBAAqB;CACrB,kBAAkB;AACnB;AACA;CACC,iBAAiB;AAClB","sourcesContent":["/*======================\r\n    404 page\r\n=======================*/\r\n\r\n.page_404 {\r\n\twidth: 100%;\r\n\tpadding: 40px 0;\r\n\tbackground: #fff;\r\n\tmargin-top: 100px;\r\n\tmin-height: 80vh;\r\n}\r\n.page_404 .row {\r\n\tpadding-top: 1rem;\r\n\tpadding-bottom: 1rem;\r\n\tmargin-right: 0;\r\n\tpadding-right: 0;\r\n}\r\n\r\n.page_404 .col-sm-12 {\r\n\tmargin-bottom: 0;\r\n\tpadding-right: 0;\r\n}\r\n\r\n.page_404 .container {\r\n\tmargin-bottom: 0;\r\n\ttext-align: center;\r\n\t\r\n}\r\n\r\n.page_404 img {\r\n\twidth: 100%;\r\n}\r\n\r\n.four_zero_four_bg {\r\n\tbackground-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);\r\n\theight: 400px;\r\n\tbackground-position: center;\r\n\tbackground-repeat: no-repeat;\r\n}\r\n\r\n.four_zero_four_bg h1 {\r\n\tfont-size: 80px;\r\n}\r\n\r\n.four_zero_four_bg h3 {\r\n\tfont-size: 80px;\r\n}\r\n\r\n.link_404 {\r\n\tcolor: #fff !important;\r\n\tpadding: 10px 20px;\r\n\tbackground: #00bc92;\r\n\tmargin: 20px 0;\r\n\tdisplay: inline-block;\r\n\tborder-radius: 8px;\r\n}\r\n.contant_box_404 {\r\n\tmargin-top: -50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
