import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// Styles
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <nav>
      <ul>
        <Link
          className={
            pathname === '/home' ? 'navigation navigationActive' : 'navigation'
          }
          to='/home'
        >
          BENEFICIOS
        </Link>
        <Link
          className={
            pathname === '/request'
              ? 'navigation navigationActive'
              : 'navigation'
          }
          to='/request'
        >
          GESTIONAR
        </Link>
      </ul>
    </nav>
  );
};

export default Navbar;
