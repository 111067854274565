import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import Loader from '../../Components/Loader';
import Container from '../../Components/Container';
import CardCoupon from '../../Components/CardCoupon';
// Services
import { getBenefits } from '../../services/getBenefits';
// Images
import arrow from '../../Images/Home/arrow.png';
// Styles
import './HomePage.css';

const HomePage = () => {
  const navigate = useNavigate();
  const [benefitsAvailable, setBenefitsAvailable] = useState([]);
  const [benefitsRequested, setBenefitsRequested] = useState([]);
  const [benefitsAccepted, setBenefitsAccepted] = useState([]);

  // Estados para el menu dropdown
  const [activeAvailable, setActiveAvailable] = useState(true);
  const [activeRequested, setActiveRequested] = useState(true);
  const [activeAccepted, setActiveAccepted] = useState(true);

  const [couponStatus, setCouponStatus] = useState('initial');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.name || !localStorage.email) {
      navigate('/');
    } else {
      getBenefits(localStorage.id)
        .then((res) => {
          setBenefitsAvailable(
            res.data.filter((item) => item.estado === 0 || item.estado === 3)
          );
          setBenefitsRequested(res.data.filter((item) => item.estado === 1));
          setBenefitsAccepted(res.data.filter((item) => item.estado === 2));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching benefits:', error);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponStatus, localStorage.id]);

  // Función para actualizar la variable en el componente padre
  const couponUpdateHandler = (status) => {
    setCouponStatus(status);
  };

  return (
    <main className='coupon'>
      <Container>
        <h2 className='welcomeTitle'>Bienvenido, {localStorage.name}</h2>
        {isLoading ? (
          <section className='loadingContainer'>
            <Loader />
          </section>
        ) : (
          <section className='couponContainer'>
            <div className='benefitsContainer'>
              <div className='couponGroupTitle couponAvailableTitle'>
                <h3>Cupones disponibles ({benefitsAvailable.length})</h3>
                <button
                  onClick={() => setActiveAvailable(!activeAvailable)}
                  className={
                    activeAvailable
                      ? 'buttonDropdownCoupons activeDropdown'
                      : 'buttonDropdownCoupons'
                  }
                >
                  <img src={arrow} alt='exand/contact coupon container' />
                </button>
              </div>
              <div
                className={
                  activeAvailable
                    ? 'couponGroupContainer borderRight '
                    : 'couponGroupContainer borderRight activeView'
                }
              >
                {benefitsAvailable?.map((coupon) => (
                  <CardCoupon
                    key={coupon.id}
                    benefit_id={coupon.id}
                    benefit={coupon.beneficio}
                    requested={coupon.solicitado}
                    type={coupon.type}
                    couponUpdateHandler={couponUpdateHandler}
                    estado={coupon.estado}
                    description={coupon.descripcion}
                  />
                ))}
              </div>
            </div>

            <div className='benefitsContainer'>
              <div className='couponGroupTitle'>
                <h3>Cupones solicitados ({benefitsRequested.length})</h3>
                <button
                  onClick={() => setActiveRequested(!activeRequested)}
                  className={
                    activeRequested
                      ? 'buttonDropdownCoupons activeDropdown'
                      : 'buttonDropdownCoupons'
                  }
                >
                  <img src={arrow} alt='exand/contact coupon container' />
                </button>
              </div>
              <div
                className={
                  activeRequested
                    ? 'couponGroupContainer '
                    : 'couponGroupContainer activeView'
                }
              >
                {benefitsRequested?.map((coupon) => (
                  <CardCoupon
                    key={coupon.id}
                    benefit_id={coupon.id}
                    benefit={coupon.beneficio}
                    requested={coupon.solicitado}
                    type={coupon.type}
                    couponUpdateHandler={couponUpdateHandler}
                    estado={coupon.estado}
                    description={coupon.descripcion}
                  />
                ))}
              </div>
            </div>

            <div className='benefitsContainer'>
              <div className='couponGroupTitle couponAcceptedTitle'>
                <h3>Cupones aceptados ({benefitsAccepted.length})</h3>
                <button
                  onClick={() => setActiveAccepted(!activeAccepted)}
                  className={
                    activeAccepted
                      ? 'buttonDropdownCoupons activeDropdown'
                      : 'buttonDropdownCoupons'
                  }
                >
                  <img src={arrow} alt='exand/contact coupon container' />
                </button>
              </div>
              <div
                className={
                  activeAccepted
                    ? 'couponGroupContainer borderLeft '
                    : 'couponGroupContainer borderLeft activeView'
                }
              >
                {benefitsAccepted?.map((coupon) => (
                  <CardCoupon
                    key={coupon.id}
                    benefit_id={coupon.id}
                    benefit={coupon.beneficio}
                    requested={coupon.solicitado}
                    type={coupon.type}
                    couponUpdateHandler={couponUpdateHandler}
                    estado={coupon.estado}
                    description={coupon.descripcion}
                  />
                ))}
              </div>
            </div>
          </section>
        )}
      </Container>
    </main>
  );
};

export default HomePage;
