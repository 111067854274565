import React from 'react';
import Login from '../../Components/Login';
// Image
import LoginImage from '../../Images/Login/Login.svg';
// Styles
import './LoginPage.css';

const LoginPage = () => {
  return (
    <main className='loginPage'>
      <div className='loginPage__Image'>
        <img src={LoginImage} alt='login section' />
      </div>
      <div className='loginPage__Login'>
        <Login />
      </div>
    </main>
  );
};

export default LoginPage;
