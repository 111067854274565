import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para obtener datos de beneficios
export const getIdEmployee = async (email) => {
  try {
    let url_benefits = `/api/employees/${email}`;
    const res = await fetchWithoutToken.get(url_benefits, {
      headers: {
        Authorization: localStorage.token,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};
