import React from 'react';
import { useLocation } from 'react-router-dom';
// Components
import Logout from '../Logout';
import Navbar from '../Navbar';
import Container from '../Container';
// Styles
import './Header.css';

const Header = () => {
	const location = useLocation();
	const page = location.pathname.replace('/', '');
	if (page !== '') {
		return (
			<header>
				<Container className='header'>
					<h1>Cuponera de Beneficios</h1>
					<div className='headerNav'>
						<Navbar />
						<Logout />
					</div>
				</Container>
			</header>
		);
	} else {
		return null;
	}
};

export default Header;
