// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginPage {
  display: flex;
  height: 100vh;
}

.loginPage__Image {
  display: none;
}

@media (min-width: 760px) {
  .loginPage__Image {
    display: block;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8ff;
  }
}

.loginPage__Image img {
  width: 90%;
  max-width: 800px;
}

.loginPage__Login {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media (min-width: 760px) {
  .loginPage__Login {
    width: 40%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,cAAc;IACd,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;EAC3B;AACF;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".loginPage {\r\n  display: flex;\r\n  height: 100vh;\r\n}\r\n\r\n.loginPage__Image {\r\n  display: none;\r\n}\r\n\r\n@media (min-width: 760px) {\r\n  .loginPage__Image {\r\n    display: block;\r\n    width: 60%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: #f8f8ff;\r\n  }\r\n}\r\n\r\n.loginPage__Image img {\r\n  width: 90%;\r\n  max-width: 800px;\r\n}\r\n\r\n.loginPage__Login {\r\n  display: flex;\r\n  width: 100%;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n@media (min-width: 760px) {\r\n  .loginPage__Login {\r\n    width: 40%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
