import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import axios from 'axios';
import './index.css';

// axios.defaults.baseURL = 'http://localhost:4000';
axios.defaults.baseURL = 'https://cuponera-api.aquinasnetwork.tech/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
