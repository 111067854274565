import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Login Component
import MicrosoftLogin from 'react-microsoft-login';
// Services
import { getIdEmployee } from '../../services/getIdEmployee';
import { loginUser } from '../../services/loginUser';
// Styles
import './Login.css';

const Login = () => {
  const navigate = useNavigate();

  const user = process.env.REACT_APP_AUTH_USERNAME;
  const pass = process.env.REACT_APP_PASSWORD;
  const microsoftClientId = process.env.REACT_APP_MICROSOFT_CLIENTID || '';

  const [errorMessage, setErrorMessage] = useState('');

  const loginHandler = (err, data) => {
    if (!err && data) {
      const email = data.account.username;

      loginUser(email, user, pass)
        .then((res) => {
          localStorage.setItem('token', res.data.token);
          getIdEmployee(email)
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem('id', res.data.id);
                localStorage.setItem(
                  'birthday',
                  res.data.fecha_nacimiento.split('T')[0]
                );

                localStorage.setItem('email', email);
                localStorage.setItem('name', data.account.name);
                if (localStorage.request === 'request') {
                  navigate('/request');
                } else {
                  navigate(`/home`);
                }
              } else {
                setErrorMessage(
                  'User does not exist. Contact the IT department to be registered.'
                );
                setTimeout(() => {
                  setErrorMessage('');
                }, 5000);
              }
            })
            .catch((e) => console.error(e));
        })
        .catch((e) => console.error(e));
    }
  };

  if (errorMessage.length === 0) {
    return (
      <button className='loginButton'>
        <MicrosoftLogin
          clientId={microsoftClientId}
          authCallback={loginHandler}
        >
          <span>Login with Microsoft</span>
        </MicrosoftLogin>
      </button>
    );
  } else {
    return <p className='noUser'>{errorMessage}</p>;
  }
};

export default Login;
