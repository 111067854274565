import HomePage from '../HomePage';
import LoginPage from '../LoginPage';
import RequestPage from '../RequestPage';
import NotFound from '../NotFound';

const appPages = [
	{
		path: '/home',
		title: 'Home',
		element: <HomePage />,
	},
	{
		path: '/',
		title: 'Login',
		element: <LoginPage />,
	},
	{
		path: '/request',
		title: 'Request',
		element: <RequestPage />,
	},
	{
		path: '*',
		title: '*',
		element: <NotFound />,
	},
];

export default appPages;
