// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  margin-left: 10px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid #1b2831;
  border-right: 5px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  text-align: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,6BAA6B;EAC7B,mCAAmC;EACnC,sBAAsB;EACtB,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader {\r\n  margin-left: 10px;\r\n  width: 70px;\r\n  height: 70px;\r\n  border-radius: 50%;\r\n  display: inline-block;\r\n  border-top: 5px solid #1b2831;\r\n  border-right: 5px solid transparent;\r\n  box-sizing: border-box;\r\n  animation: rotation 1s linear infinite;\r\n  text-align: center;\r\n}\r\n\r\n@keyframes rotation {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
