import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para obtener datos de beneficios
export const loginUser = async (username, user, pass) => {
  try {
    let url_benefits = '/api/user/login';
    const res = await fetchWithoutToken.post(url_benefits, {
      username,
      user,
      pass,
    });
    return res;
  } catch (error) {
    console.error(error);
    return [];
  }
};
