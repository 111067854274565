// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoutButton {
  height: 40px;
  background-color: #2d81bc;
  color: white;
  width: 60px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

@media (min-width: 850px) {
  .logoutButton {
    width: 120px;
  }
}

.logoutButton span {
  display: none;
}

@media (min-width: 850px) {
  .logoutButton span {
    display: block;
  }
}

.logoutIcon {
  font-size: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Logout/Logout.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".logoutButton {\r\n  height: 40px;\r\n  background-color: #2d81bc;\r\n  color: white;\r\n  width: 60px;\r\n  border-radius: 6px;\r\n  cursor: pointer;\r\n  font-size: 16px;\r\n  font-weight: 600;\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 10px;\r\n  justify-content: center;\r\n}\r\n\r\n@media (min-width: 850px) {\r\n  .logoutButton {\r\n    width: 120px;\r\n  }\r\n}\r\n\r\n.logoutButton span {\r\n  display: none;\r\n}\r\n\r\n@media (min-width: 850px) {\r\n  .logoutButton span {\r\n    display: block;\r\n  }\r\n}\r\n\r\n.logoutIcon {\r\n  font-size: 25px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
