import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para obtener datos de beneficios
export const getRequest = async (employee_id) => {
	try {
		let url_benefits = `/api/request/${employee_id}`;
		const res = await fetchWithoutToken.get(url_benefits, {
			headers: {
				Authorization: localStorage.token,
			},
		});
		return res;
	} catch (error) {
		console.error(error);
		return [];
	}
};
