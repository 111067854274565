import Header from './Components/Header';
import Router from './Views/Routes/router';

function App() {
	return (
		<div className='App'>
			<Header />
			<Router />
		</div>
	);
}

export default App;
